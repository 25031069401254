import type CloudStorageProject from './cloud-storage-project';
import type { DataItem } from './types';
import type { CloudStorageItemConnection } from '../cloud-storage/types';
import type { CloudStoragePassthroughItem } from '@mtb/cloud-storage/types';
import CloudStorageClient from '../cloud-storage';

class PassthroughItem implements DataItem {
  #id: string;
  #name: string;

  get id(): string {
    return this.#id;
  }

  get name(): string {
    return CloudStorageClient.getNameParts(this.#name).displayName;
  }

  get extension(): string {
    return CloudStorageClient.getNameParts(this.#name).extension;
  }

  get connection(): CloudStorageItemConnection {
    return {
      id     : 'passthrough',
      driveId: '',
      type   : 'local',
    };
  }

  constructor(id: string, name: string) {
    this.#id = id;
    this.#name = name;
  }

  doesNameMatch(name: string) : boolean {
    return `${this.name}.${this.extension}` === name;
  }

  verifyBeforeOpen() : boolean {
    return true;
  }

  duplicate() : Promise<void> {
    return Promise.resolve();
  }

  rename(name: string) : Promise<void> {
    this.#name = name;
    return Promise.resolve();
  }

  createPassthrough(): Promise<CloudStoragePassthroughItem> {
    const nameParts = CloudStorageClient.getNameParts(this.#name);
    return Promise.resolve({
      projectId  : this.#id,
      name       : nameParts.name,
      displayName: nameParts.displayName,
      extension  : nameParts.extension,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createProject(name: string): Promise<CloudStorageProject> {
    throw new Error('Method not implemented.');
  }
}

export default PassthroughItem;
