import type { DataItem } from './types';
import type { StorageProviderItem } from '@mtb/cloud-storage/types';
import CloudStorageClient from '../cloud-storage';
import CloudItem from './cloud-item';
import FileItem from './file-item';
import PassthroughItem from './passthrough-item';
import { decodeDataRef } from './utils';

class DataClient {
  /**
   * Create a data item from a file or cloud data item.
   * @param options - The options to create the data item with.
   * @returns - The created data item.
   */
  async create(item: string | File | StorageProviderItem): Promise<DataItem> {
    switch (true) {
      case item instanceof File:
        return this.#createItemFromFile(item);
      case typeof item === 'string':
        return this.#createItemFromDataId(item);
      case typeof item === 'object' && item.hasOwnProperty('id') && item.hasOwnProperty('type'):
        return this.#createItemFromStorageItem(item);
      default:
        throw new Error('Unable to create data item.');
    }
  }

  /**
   * Create a data item from a file.
   * @param file - The file to create the data item from.
   * @returns - The created data item.
   */
  #createItemFromFile(file: File): DataItem {
    return new FileItem(file);
  }

  /**
   * Create a data item from a cloud storage item.
   * @param storageItem - The cloud storage item to create the data item from.
   * @returns - The created data item.
   */
  #createItemFromStorageItem(storageItem: StorageProviderItem): DataItem {
    return new CloudItem(storageItem);
  }

  /**
   * Create a data item from a data id.
   * @param dataId - The data id to create the data item from.
   * @returns - The created data item.
   */
  async #createItemFromDataId(dataId: string): Promise<DataItem> {
    const connection = decodeDataRef(dataId).connection;
    const storageItem = await CloudStorageClient.getItemById(connection);
    if (!storageItem) {
      throw new Error('Failed to get data item from connection.');
    }
    return new CloudItem(storageItem);
  }

  createFromPassthrough(id: string, name: string) : DataItem {
    return new PassthroughItem(id, name);
  }
}

const dataClient = new DataClient();
export default dataClient;
