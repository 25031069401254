import type { ComponentType, PropsWithChildren } from 'react';
import { createElement } from 'react';
import PlatformModule from '../platform-module';
import PlatformModuleContext from '../platform-module/context';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withIntegratedPlatformModule = (Component: ComponentType<any>) => {
  return (props: PropsWithChildren<{ planeId: string }>) => {
    const platform = PlatformModule.getOrCreateIntegratedPlatformModule(props.planeId);
    return createElement(
      PlatformModuleContext.Provider,
      { value: platform },
      createElement(Component, {
        ...props,
        platform,
      }),
    );
  };
};

export default withIntegratedPlatformModule;
