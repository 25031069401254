import type { RemoteModuleThemeOptions } from '../types';
import type { Theme } from '@mtb/ui/types/core/theme';

type ScaledZIndex = Theme['zIndex'] & { [key: string]: number };

/**
 * Scales the z-index values of a theme.
 *
 * @param zIndex - The z-index values to scale.
 * @param scale - The amount to scale the z-index values by.
 * @returns The scaled z-index values.
 */
export function scaleZIndex(zIndex: ScaledZIndex, scale: number): ScaledZIndex {
  return Object.keys(zIndex).reduce((acc, key) => {
    const value = typeof zIndex[key as keyof ScaledZIndex] === 'number' ? zIndex[key as keyof ScaledZIndex] : 0;
    acc[key] = Math.max(value + scale, 0) as typeof value;
    return acc;
  }, {} as ScaledZIndex);
}

/**
 * Creates a theme for a remote module based on the platform theme.
 *
 * @param {Theme} platformTheme - The platform theme to base the remote module theme on.
 * @param {RemoteModuleThemeOptions} options - Options for createRemoteModuleTheme
 * @returns {Theme} The remote module theme.
 */
export function createRemoteModuleTheme(platformTheme: Theme, options : RemoteModuleThemeOptions) {
  return {
    ...platformTheme,
    ...(options.shiftZIndex ? { zIndex: scaleZIndex(platformTheme.zIndex, -100) } : {}),
  };
}
