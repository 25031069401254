import config from '../config';
import BRAINSTORM from './brainstorm';
import DASHBOARD from './dashboard';
import DATACENTER from './datacenter';
import DISCOVER from './discover';
import LEARNING_CENTER from './learning-center';
import MSSO from './msso';
import PLATFORM from './platform';
import WSO from './wso';

/**
 * All the modules that are available in the platform.
 */
export const MODULES = {
  PLATFORM,
  MSSO,
  BRAINSTORM,
  WSO,
  DATACENTER,
  DASHBOARD,
  DISCOVER,
  LEARNING_CENTER,
};

/**
 * The order in which the modules should be displayed in Platform.
 */
export const MODULE_SORT_ORDER = [
  MODULES.DATACENTER.key,
  MODULES.WSO.key,
  MODULES.BRAINSTORM.key,
  MODULES.MSSO.key,
  MODULES.DASHBOARD.key,
];

/**
 * Object of the module configuration in Platform that are feature flagged.
 */
export const FEATURE_FLAGGED_MODULES = {
  [MODULES.DISCOVER.key]       : config.feature_flag_ui_v2,
  [MODULES.LEARNING_CENTER.key]: config.feature_flag_ui_v2,
};

export default MODULES;
