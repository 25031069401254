import type { ActionsMap, MessageHandler } from './types';

/**
   * @class PlaneChannel
   * @description
   * A simple two-way communication channel between Planes.
   */
export class PlaneChannel {
  /** @type {string} */
  #planeId = '';

  /** @type {string} */
  #peerPlaneId = '';

  /** @type {ActionsMap} */
  #actions: ActionsMap = {};
  
  /** @type {MessageHandler | null} */
  #messageHandler: MessageHandler | null = null;
  
  /** @type {(channel: PlaneChannel) => void | null} */
  #onConnectedCallback: ((channel: PlaneChannel) => void) | null = null;


  get planeId() {
    return this.#planeId;
  }

  get peerPlaneId() {
    return this.#peerPlaneId;
  }

  get actions() {
    return this.#actions;
  }
  /**
     * Creates an instance of PlaneChannel.
     * @param {string} planeId
     */
  constructor(planeId: string, peerPlaneId: string) {
    this.#planeId = planeId;
    this.#peerPlaneId = peerPlaneId;
  }
  
  /**
     * Sets the actions (methods) that this channel can perform.
     * @param {ActionsMap} actions - An object mapping action names to functions.
     */
  setActions(actions: ActionsMap): void {
    this.#actions = actions;
  }
  
  /**
     * Sets the message handler function to process incoming messages.
     * @param {MessageHandler} messageHandler - A function to handle incoming messages.
     */
  setMessageHandler(messageHandler: MessageHandler): void {
    this.#messageHandler = messageHandler;
  }
  
  /**
     * Sends a message with a specified type and payload to this instance.
     * A Channel initiator would call channel.peer.send() and a receiver would call channel.send()
     * @param {string} type - The type/category of the message.
     * @param {any} payload - The data associated with the message.
     * @returns {Promise<any>}
     */
  async send(type: string, payload: any): Promise<any> { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.#messageHandler?.(type, payload);
  }
  
  /**
     * Connects this channel to a peer PlaneChannel instance.
     * Establishes a two-way communication link.
     * @param {PlaneChannel} channel - The peer PlaneChannel instance to connect with.
     * @throws {Error} If the provided channel is not an instance of PlaneChannel.
     */
  connect(channel: PlaneChannel): void {
    if (this.#onConnectedCallback) {
      this.#onConnectedCallback(channel);
    }
  }

  /** @param {Function} callback */
  onConnected(callback: (channel: PlaneChannel) => void) {
    this.#onConnectedCallback = callback;
  }
}
  
export default PlaneChannel;
  
