import type { OpenOptions } from './types';
import type { DataItem } from '../../clients/data/types';
import type { I18nLocale } from '../../clients/i18n/types';
import type DataService from '../services/data';
import type { CreateOptions, CreateResult, OpenOptions as DeprecatedOpenOptions, OpenResult as OldOpenResult, OpenResultV2 as OpenResult } from '../services/plane/types';
import type { UserSettings, UserSubscription } from '../store/reducers/user/types';
import PlatformCore from '..';
import moduleClient from '../../clients/module';
import PlaneChannel from '../services/channel';
import platformStore from '../store';

/**
 * PlatformModuleBase is the base class that is used by all other platform module
 * classes to extend off of to provide consistent and shared functionality for
 * the different variations of platform modules.
 */
class PlatformModuleBase {
  /**
   * Get user settings.
   */
  get settings(): UserSettings {
    return PlatformCore.selectors.settings(PlatformCore.getState());
  }

  /**
   * Get user subscriptions.
   */
  get subscriptions(): UserSubscription[] {
    return PlatformCore.selectors.subscriptions(PlatformCore.getState());
  }

  /**
   * Get user locale.
   */
  get locale(): I18nLocale {
    return PlatformCore.selectors.locale(PlatformCore.getState());
  }

  /**
   * Get the data service.
   */
  get data(): DataService {
    return PlatformCore.Data;
  }

  /**
   * Opens an channel to another plane by ID
   * @param planeId - The ID of the plane to open a channel with
   * @param channel - The PlaneChannel to pass
   * @returns The opened analysis plane.
   */
  openChannel(planeId: string, channel: PlaneChannel): boolean {
    const plane = platformStore.selectors.plane(platformStore.getState(), planeId);
    if (plane) {
      moduleClient.Events.openChannel(plane, channel);
      return true;
    }
    return false;
  }

  /**
   * Creates a plane channel
   * @param planeId - The ID of the plane creating the channel
   * @param peerPlaneId - The ID of the channel's intended receiver
   * @returns The new plane channel
   */
  createChannel(planeId: string, peerPlaneId: string) {
    return new PlaneChannel(planeId, peerPlaneId);
  }

  /**
   * Uses a selector for claims.
   */
  useClaims(): unknown {
    return PlatformCore.useSelector(PlatformCore.selectors.claims);
  }

  /**
   * Hook to get the user's subscriptions.
   */
  useSubscriptions(): UserSubscription[] {
    return PlatformCore.useSelector(PlatformCore.selectors.subscriptions);
  }

  /**
   * Uses a selector to get the user's locale settings.
   * @returns The locale settings.
   */
  useLocale(): I18nLocale | undefined {
    return PlatformCore.useSelector(PlatformCore.selectors.locale);
  }

  async open({ moduleKey, ...options }: OpenOptions): Promise<OpenResult> {
    // appease the parser, eventually we won't use invokeByModuleKey
    const data = options.data as DataItem;

    const planeId = await PlatformCore.Plane.invokeByModuleKey(moduleKey, { ...options, data });

    return { planeId: planeId || '' };
  }

  /**
   * Creates a new MSSO analysis plane with the given options.
   * @param options - The options to create the analysis with.
   * @returns The created analysis plane.
   */
  async createAnalysis(options: CreateOptions = {}): CreateResult {
    return await PlatformCore.Plane.createAnalysis(options);
  }

  /**
   * Creates a new Dashboard plane with the given options.
   * @param options - The options to create the analysis with.
   * @returns The created analysis plane.
   */
  async createDashboard(options: CreateOptions = {}): CreateResult {
    return await PlatformCore.Plane.createDashboard(options);
  }

  /**
   * Opens an analysis plane with the given options.
   * @param options - The options to open the analysis with.
   * @returns The opened analysis plane.
   */
  async openAnalysis(options: DeprecatedOpenOptions): OldOpenResult {
    return await PlatformCore.Plane.openAnalysis(options);
  }
}

export default PlatformModuleBase;
